import { ErrorsEnum } from './types';

export const enUsErrorTranslations = {
  errors: {
    generic_error: {
      title: 'Oops! Something went wrong',
      description: 'An error occurred while processing your request. Please try again later.',
      button: 'Understood',
    },
    [ErrorsEnum.AUTHENTICATION_FAILED]: {
      title: 'Oops! Something went wrong',
      description: 'Authentication failed. Please try again.',
      button: 'Understood',
    },
    [ErrorsEnum.ACCESS_CODE_NOT_EXISTS]: {
      title: 'Invalid access code',
      description: 'The access code provided is invalid. Please check the code and try again.',
      button: 'Understood',
    },
    [ErrorsEnum.EXPIRED_ACCESS_CODE]: {
      title: 'Oops! Something went wrong',
      description: 'The access code has expired. Please request a new code.',
      button: 'Understood',
    },
    [ErrorsEnum.INVALID_PASSWORD_POLICY]: {
      title: 'Invalid password',
      description: 'The password provided does not meet the security requirements. Please try again.',
      button: 'Understood',
    },
    [ErrorsEnum.PASSWORD_UPDATE_FAIL]: {
      title: 'Oops! Something went wrong',
      description: 'An error occurred while updating the password. Please try again.',
      button: 'Understood',
    },
    [ErrorsEnum.ACCOUNT_ALREADY_EXISTS]: {
      title: 'Account already exists',
      description: 'An account with the provided email already exists. Please try again.',
      button: 'Understood',
    },
    [ErrorsEnum.TOKEN_WITHOUT_SCOPES]: {
      title: 'Oops! Something went wrong',
      description: 'The token does not have the necessary permissions. Please try again.',
      button: 'Understood',
    },
    [ErrorsEnum.UNAUTHORIZED_USER]: {
      title: 'Unauthorized user',
      description: 'You do not have permission to access this resource. Please contact the administrator.',
      button: 'Understood',
    },
    [ErrorsEnum.EXPIRED_TOKEN]: {
      title: 'Session expired',
      description: 'Your session has expired. Please log in again.',
      button: 'Understood',
    },
    [ErrorsEnum.ZIPCODE_NOT_FOUND]: {
      title: 'Zip code not found',
      description: 'The zip code provided was not found. Please check the code and try again.',
      button: 'Understood',
    },
    [ErrorsEnum.MUST_CONTAIN_EIGHT_DIGITS]: {
      title: 'Oops! Something went wrong',
      description: 'The value must contain eight digits. Please check the code and try again.',
      button: 'Understood',
    },
    [ErrorsEnum.INCONSISTENT_ZIPCODE_LIB]: {
      title: 'There was a problem verifying the ZIP code',
      description: 'Please try again later.',
      button: 'Understood',
    },
    [ErrorsEnum.EMAIL_NOT_SENT]: {
      title: 'Unable to send the email',
      description: 'Please try again later.',
      button: 'Understood',
    },
    [ErrorsEnum.DOCK_ONE_ERROR]: {
      title: 'Oops! Something went wrong',
      description: 'An issue occurred while processing your request. Please try again later.',
      button: 'Understood',
    },
    [ErrorsEnum.DOCK_ONE_BAD_REQUEST]: {
      title: 'Oops! Something went wrong',
      description:
        'The submitted request contains incorrect data. Please check the information and try again.',
      button: 'Understood',
    },
    [ErrorsEnum.DOCK_ONE_NOT_FOUND]: {
      title: 'Oops! Something went wrong',
      description: 'Please check the provided data.',
      button: 'Understood',
    },
    [ErrorsEnum.DOCK_ONE_UNPROCESSABLE_ENTITY]: {
      title: 'Oops! Something went wrong',
      description: 'The provided data could not be processed. Please review and try again.',
      button: 'Understood',
    },
    [ErrorsEnum.TENANT_ID_IS_REQUIRED]: {
      title: 'Oops! Something went wrong',
      description: 'An error occurred while processing your request. Please try again later.',
      button: 'Understood',
    },
    [ErrorsEnum.DELETE_USER_IN_FIREBASE]: {
      title: 'Oops! Something went wrong',
      description: 'Unable to remove the user. Please try again later.',
      button: 'Understood',
    },
    [ErrorsEnum.CANNOT_ASSOCIATE_PRIMARY_TENANT]: {
      title: 'Oops! Something went wrong',
      description:
        'Unable to complete the primary resource association. Please check permissions and try again.',
      button: 'Understood',
    },
    [ErrorsEnum.VALIDATION_INVALID]: {
      title: 'Oops! Something went wrong',
      description: 'Incorrect verification code.',
      button: 'Understood',
    },
    [ErrorsEnum.DOCUMENT_IS_REQUIRED]: {
      title: 'Document required',
      description: 'A valid document is required to proceed.',
      button: 'Understood',
    },
    [ErrorsEnum.VALIDATION_REQUIRED]: {
      title: 'Validation required',
      description: 'This field is mandatory. Please fill it out before continuing.',
      button: 'Understood',
    },
    [ErrorsEnum.USER_EMAIL_UNIQUE]: {
      title: 'Oops! Something went wrong',
      description: 'The provided email is already in use. Please use a different email.',
      button: 'Understood',
    },
    [ErrorsEnum.USER_DOCUMENT_NUMBER_UNIQUE]: {
      title: 'Oops! Something went wrong',
      description: 'The provided document is already in use. Please use a different document.',
      button: 'Understood',
    },
    [ErrorsEnum.USER_NOT_FOUND]: {
      title: 'User not found',
      description: 'We couldn’t find a user with the provided data. Please check and try again.',
      button: 'Understood',
    },
    [ErrorsEnum.CANNOT_DELETE_THE_USER]: {
      title: 'Cannot delete the user',
      description: 'This user cannot be deleted at the moment. Please try again later.',
      button: 'Understood',
    },
    [ErrorsEnum.UNCREATED_CODE]: {
      title: 'Code not created',
      description: 'The requested code was not created. Please check the request and try again.',
      button: 'Understood',
    },
    [ErrorsEnum.UNKNOWN_DATABASE_ERROR]: {
      title: 'Unknown error',
      description: 'An unexpected error occurred. Please try again later.',
      button: 'Understood',
    },
    [ErrorsEnum.UNAUTHORIZED_ACTION]: {
      title: 'Unauthorized action',
      description: 'You do not have permission to perform this action. Please contact the administrator.',
      button: 'Understood',
    },
    [ErrorsEnum.UNAUTHORIZED_ASSOCIATION]: {
      title: 'Unauthorized Association',
      description: 'You do not have permission to associate this employee. Please contact the administrator.',
      button: 'Understood',
    },
    [ErrorsEnum.UNAUTHORIZED_DISSOCIATION]: {
      title: 'Unauthorized Dissociation',
      description:
        'You do not have permission to dissociate this employee. Please contact the administrator.',
      button: 'Understood',
    },
    [ErrorsEnum.BATCH_PROPOSAL_NO_FILE]: {
      title: 'No File Provided for Batch Proposal',
      description: 'Attach a valid file and try again.',
      button: 'Understood',
    },
    [ErrorsEnum.BATCH_PROPOSAL_EMPTY_FILE]: {
      title: 'Empty Batch Proposal File',
      description:
        'The file provided for the batch proposal is empty. Please provide a file with valid data.',
      button: 'Understood',
    },
    [ErrorsEnum.BATCH_PROPOSAL_INVALID_FILE]: {
      title: 'Invalid Batch Proposal File',
      description:
        "The file provided for the batch proposal is invalid. Check the correct format in the 'template spreadsheet' and try again.",
      button: 'Understood',
    },
    [ErrorsEnum.BATCH_PROPOSAL_EMPTY_COLUMNS]: {
      title: 'Empty Columns in Batch Proposal',
      description:
        'The batch proposal file contains empty columns. Fill in the columns properly or remove them and try again.',
      button: 'Understood',
    },
    [ErrorsEnum.BATCH_PROPOSAL_INVALID_COLUMNS]: {
      title: 'Invalid Columns in Batch Proposal',
      description:
        "The batch proposal file contains invalid columns. Check the format in the 'template spreadsheet' and try again.",
      button: 'Understood',
    },
    [ErrorsEnum.BATCH_PROPOSAL_MAX_BATCH_SIZE]: {
      title: 'Maximum Batch Size Exceeded',
      description: 'The batch size exceeds the allowed limit.',
      button: 'Understood',
    },
    [ErrorsEnum.BATCH_PROPOSAL_MIN_BATCH_SIZE]: {
      title: 'Minimum Batch Size Not Met',
      description: 'The batch size is smaller than the allowed minimum. Add one or more items and try again.',
      button: 'Understood',
    },
    [ErrorsEnum.BATCH_PROPOSAL_FILE_WITHOUT_ERRORS]: {
      title: 'Batch Proposal File Without Errors',
      description: 'The provided file contains no errors.',
      button: 'Understood',
    },
    [ErrorsEnum.BATCH_PROPOSAL_NOT_FOUND_AT_DATABASE]: {
      title: 'Batch Proposal Not Found',
      description: 'The batch proposal was not found. Check the reference and try again.',
      button: 'Understood',
    },
    [ErrorsEnum.BATCH_PROPOSAL_NO_ERRORS_OR_REFUSED]: {
      title: 'Oops! Something went wrong',
      description: 'Check the data before resubmitting.',
      button: 'Understood',
    },
    [ErrorsEnum.BATCH_PROPOSAL_EMPTY_ROW]: {
      title: 'Empty Rows in Batch Proposal',
      description:
        "The batch proposal contains empty rows. Fill in all fields according to the 'template spreadsheet' and try again.",
      button: 'Understood',
    },
    [ErrorsEnum.LOAD_ORDER_NO_FILE]: {
      title: 'No File Provided for Batch Recharge',
      description: 'No file was uploaded for the batch recharge. Attach a valid file and try again.',
      button: 'Understood',
    },
    [ErrorsEnum.LOAD_ORDER_EMPTY_FILE]: {
      title: 'Empty File in Batch Recharge',
      description: 'The uploaded file is empty. Check the file content and try again.',
      button: 'Understood',
    },
    [ErrorsEnum.LOAD_ORDER_INVALID_EXTENSION]: {
      title: 'Invalid File Format in Batch Recharge',
      description: 'Use an accepted format and try again.',
      button: 'Understood',
    },
    [ErrorsEnum.LOAD_ORDER_EMPTY_COLUMNS]: {
      title: 'Empty Columns in Batch Recharge',
      description: 'The uploaded file contains empty columns. Complete all columns and try again.',
      button: 'Understood',
    },
    [ErrorsEnum.LOAD_ORDER_INVALID_COLUMNS]: {
      title: 'Invalid Columns in Batch Recharge',
      description:
        "The uploaded file contains invalid columns. Check the correct format in the 'template spreadsheet' and try again.",
      button: 'Understood',
    },
    [ErrorsEnum.LOAD_ORDER_MIN_ITEMS]: {
      title: 'Minimum Item Count Not Met',
      description:
        'The number of items in the batch recharge is below the allowed minimum. Add one or more items and try again.',
      button: 'Understood',
    },
    [ErrorsEnum.LOAD_ORDER_MAX_ITEMS]: {
      title: 'Maximum Item Count Exceeded',
      description:
        'The batch size exceeds the allowed limit. The maximum accepted is 10,000 rows. Adjust and try again.',
      button: 'Understood',
    },
    [ErrorsEnum.LOAD_ORDER_INVALID_VALUES]: {
      title: 'Invalid values in batch recharge',
      description: 'The file contains invalid values. Please correct the data and try again.',
      button: 'Understood',
    },
    [ErrorsEnum.LOAD_ORDER_CPF_NOT_FOUND]: {
      title: 'CPF not found in batch recharge',
      description: 'We could not find the CPF in the batch recharge. Please check the number and try again.',
      button: 'Understood',
    },
    [ErrorsEnum.LOAD_ORDER_DUPLICATED_CPF]: {
      title: 'Duplicate CPF in batch recharge',
      description: 'The provided CPF already exists in the batch recharge. Remove duplicates and try again.',
      button: 'Understood',
    },
    [ErrorsEnum.ORDER_EMPTY_ROW]: {
      title: 'Empty rows in batch recharge',
      description: 'The recharge contains empty rows. Fill in all fields and try again.',
      button: 'Understood',
    },
    [ErrorsEnum.ORDER_INVALID_SPLIT_SCHEDULE_DATE]: {
      title: 'Invalid scheduling date for order distribution',
      description: 'The scheduling date for order distribution is invalid. Please check and try again.',
      button: 'Understood',
    },
    [ErrorsEnum.ORDER_SPLIT_SCHEDULE_DATE_BEFORE_TODAY]: {
      title: 'Scheduling date for distribution is before today',
      description:
        'The scheduling date for order distribution cannot be before today. Adjust the date and try again.',
      button: 'Understood',
    },
    [ErrorsEnum.ORDER_SPLIT_SCHEDULE_DATE_INVALID_TED]: {
      title: 'Invalid scheduling date for TED distribution',
      description:
        'The scheduling date for TED distribution is invalid. Please check the data and try again.',
      button: 'Understood',
    },
    [ErrorsEnum.EMPLOYEE_NOT_FOUND]: {
      title: 'Employee not found',
      description: 'We could not find an employee with the provided data. Please check and try again.',
      button: 'Understood',
    },
    [ErrorsEnum.ADDRESS_LIMIT_REACHED]: {
      title: 'Address limit reached',
      description: 'The address limit has been reached. You cannot add more addresses at this time.',
      button: 'Understood',
    },
    [ErrorsEnum.ADDRESS_NOT_FOUND]: {
      title: 'Address not found',
      description: 'The provided address could not be found. Please check the data and try again.',
      button: 'Understood',
    },
    [ErrorsEnum.EMPLOYER_NOT_FOUND]: {
      title: 'Employer not found',
      description: 'We could not find the employer with the provided data. Please check and try again.',
      button: 'Understood',
    },
    [ErrorsEnum.EMPLOYER_DOCUMENT_NOT_FOUND]: {
      title: 'Employer document not found',
      description: "We could not locate the employer's document. Please check the data and try again.",
      button: 'Understood',
    },
    [ErrorsEnum.EMPLOYER_ACCOUNT_NOT_FOUND]: {
      title: 'Employer account not found',
      description: 'The employer account could not be found. Please check the data and try again.',
      button: 'Understood',
    },
    [ErrorsEnum.EMPLOYER_ALREADY_EXISTS]: {
      title: 'Employer already exists',
      description: 'An employer with the provided data already exists. Please check the data and try again.',
      button: 'Understood',
    },
    [ErrorsEnum.ORDER_ALREADY_PROCESSED]: {
      title: 'Order already processed',
      description: 'This order has already been processed previously. Please check the status and try again.',
      button: 'Understood',
    },
    [ErrorsEnum.ORDER_NOT_FOUND]: {
      title: 'Order not found',
      description:
        'We could not locate the order with the provided data. Please check the data and try again.',
      button: 'Understood',
    },
    [ErrorsEnum.ORDER_PROCESSING]: {
      title: 'Order processing',
      description: 'Please wait and try again later.',
      button: 'Understood',
    },
    [ErrorsEnum.MODE_DOES_NOT_ALLOW_SPLIT]: {
      title: 'Oops! Something went wrong',
      description:
        'The distribution mode does not allow order splitting. Please check the mode and try again.',
      button: 'Understood',
    },
    [ErrorsEnum.STATUS_DOES_NOT_ALLOW_SPLIT]: {
      title: 'Oops! Something went wrong',
      description: 'The order status does not allow distribution. Please check the status and try again.',
      button: 'Understood',
    },
    [ErrorsEnum.CARDS_QUANTITY_MAX]: {
      title: 'Maximum number of cards exceeded',
      description: 'The number of cards exceeds the allowed maximum.',
      button: 'Understood',
    },
    [ErrorsEnum.CARDS_QUANTITY_MIN]: {
      title: 'Minimum number of cards not met',
      description: 'The number of cards is less than the minimum allowed. Add more cards and try again.',
      button: 'Understood',
    },
    [ErrorsEnum.CARDS_EXPIRATION_DATE_TOO_EARLY]: {
      title: 'Card expiration date not allowed',
      description:
        'The expiration date of the provided card is too close. Choose a later date and try again.',
      button: 'Understood',
    },
    [ErrorsEnum.INVALID_FILE]: {
      title: 'Oops! Something went wrong',
      description: 'The selected file is not a compatible format.',
      button: 'Understood',
    },
    [ErrorsEnum.SPLIT_ORDER]: {
      title: 'Oops! Something went wrong',
      description: 'Failed to distribute the order. Please try again.',
      button: 'Understood',
    },
    [ErrorsEnum.UNPROCESSABLE_ENTITY]: {
      title: 'Oops! Something went wrong',
      description: 'Invalid data.',
      button: 'Understood',
    },
    [ErrorsEnum.INVALID_EMPLOYER_PHONE]: {
      title: 'Oops! Something went wrong',
      description: 'Invalid employer phone number.',
      button: 'Understood',
    },
    [ErrorsEnum.BATCH_STATUS_ERROR]: {
      title: 'Oops! Something went wrong',
      description: 'Could not update the batch statuses.',
      button: 'Understood',
    },
  },
};
