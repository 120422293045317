import { TableBody, TableHead, TableHeadCell } from '@vizir-banking/design-system/web';
import { Fragment, ReactElement } from 'react';

import {
  GetSpecificBatchDetailsResponseDto,
  OnboardingStatusEnum,
  Proposal,
  ProposalDetailed,
} from '~/api/requests';
import { useTranslation } from '~/translates/use-translate';

import { Skeleton, TableBodyRow, TableCell, TableHeadRow, TableTagCell } from '../table.styles';
import { getProposalsValue, getTagStyle, hasError } from '../table.utils';

interface IProposalsTable {
  data: GetSpecificBatchDetailsResponseDto;
  isLoading?: boolean;
}

export const ProposalsTableLayout = ({ data, isLoading }: IProposalsTable): ReactElement => {
  const translate = useTranslation('components.tables');
  const columns = [
    'line',
    'status',
    'personFullName',
    'cpf',
    'email',
    'birthDate',
    'phone',
    'addressPostalCode',
    'addressStreet',
    'addressNumber',
    'addressComplement',
    'addressNeighborhood',
    'addressCity',
    'addressAdministrativeAreaCode',
    'addressCountryCode',
    'motherName',
    'preferredName',
    'gender',
    'emancipatedMinor',
    'maritalStatus',
  ];

  const renderTableHeader = () => (
    <TableHead>
      <TableHeadRow>
        {columns.map((header) => (
          <TableHeadCell key={header}>{translate(`proposals.header.${String(header)}`)}</TableHeadCell>
        ))}
      </TableHeadRow>
    </TableHead>
  );

  const renderTableLine = (line: number) => <TableCell>{line}</TableCell>;
  const renderTableTagCell = (status: OnboardingStatusEnum) => (
    <TableTagCell tagProps={getTagStyle(status)}>{translate(`status.${status}`)}</TableTagCell>
  );
  const renderTableCell = (proposal: ProposalDetailed, column: string, index: number) => {
    if (column === 'line') return renderTableLine(proposal.line);
    if (column === 'status') return renderTableTagCell(proposal.status);
    if (column === 'phone')
      return (
        <TableCell hasError={hasError(proposal, column, index)}>
          {getProposalsValue(
            `${proposal.proposal.phoneDiallingCode}-${proposal.proposal.phoneAreaCode}${proposal.proposal.phoneNumber}`,
            column
          )}
        </TableCell>
      );

    return (
      <TableCell hasError={hasError(proposal, column, index)}>
        {getProposalsValue(proposal.proposal?.[column as keyof Proposal], column)}
      </TableCell>
    );
  };

  const renderTableBody = () => {
    return (
      <TableBody>
        {data?.preview?.map((proposal, index) => (
          <TableBodyRow key={index}>
            {columns.map((column) => renderTableCell(proposal, column as keyof Proposal, index))}
          </TableBodyRow>
        ))}
      </TableBody>
    );
  };

  const renderSkeletonLoadingRows = () => {
    const renderSkeletonCell = () => (
      <TableCell>
        <Skeleton />
      </TableCell>
    );

    const renderSkeletonRow = () => <TableBodyRow>{columns.map((_) => renderSkeletonCell())}</TableBodyRow>;

    return Array.from({ length: 3 }).map((_) => renderSkeletonRow());
  };

  return (
    <Fragment>
      {renderTableHeader()}
      {isLoading ? renderSkeletonLoadingRows() : renderTableBody()}
    </Fragment>
  );
};
