import { TagProps as BaseTagProps, TagSizes, TagTypes, TagVariants } from '@vizir-banking/design-system/web';
import { formatToCEP, formatToCNPJ, formatToCPF, formatToDate, formatToPhone } from 'brazilian-values';
import { isEmpty, isNil } from 'lodash';

import { ProposalDetailed, SimplifiedEmployees } from '~/api/requests';
import { dateFormatter } from '~/utils/format-date';
import { maskDocument } from '~/utils/mask-document';

import { StatusTable } from './types';

type TagProps = Omit<BaseTagProps, 'label'>;

export const getTagStyle = (value: string): TagProps => {
  const types: { [key: string]: TagTypes } = {
    [StatusTable.PROCESSED]: TagTypes.success,
    [StatusTable.ACTIVE]: TagTypes.success,
    [StatusTable.PROCESSING]: TagTypes.warning,
    [StatusTable.PENDING]: TagTypes.warning,
    [StatusTable.ERROR]: TagTypes.neutral,
    [StatusTable.ABORTED]: TagTypes.negative,
    [StatusTable.INACTIVE]: TagTypes.negative,
    [StatusTable.PARTIAL_PROCESSED]: TagTypes.success,
    [StatusTable.FAILED]: TagTypes.neutral,
    [StatusTable.UNKNOWN]: TagTypes.neutral,
    [StatusTable.NOT_PROCESSED]: TagTypes.warning,
    [StatusTable.DECLINED]: TagTypes.negative,
    [StatusTable.APPROVED]: TagTypes.success,
    [StatusTable.ORDER.CANCELED]: TagTypes.negative,
    [StatusTable.ORDER.PENDING_PAYMENT]: TagTypes.warning,
    [StatusTable.ORDER.READY_TO_RELEASE]: TagTypes.info,
    [StatusTable.ORDER.PROCESSING_SPREADSHEET]: TagTypes.warning,
    [StatusTable.ORDER.SPREADSHEET_FAILURE]: TagTypes.neutral,
  };

  const tagProps: TagProps = {
    size: TagSizes.large,
    type: types[value.toLowerCase()],
    variant: TagVariants.primary,
  };

  return tagProps;
};

export const getValue = (type: string, value: string): string => {
  if (type === 'document') {
    value.length === 14
      ? (value = maskDocument(formatToCNPJ(value), 'cnpj'))
      : (value = maskDocument(formatToCPF(value), 'cpf'));
  }

  if (type === 'date' || type === 'createdAt') value = dateFormatter(new Date(value));

  return value;
};

const sanitizeColumnName = (column: string): string => {
  return column.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
};

export const hasError = (proposal: ProposalDetailed, columnName: string, index: number) => {
  for (let i = 0; i < proposal.errors.length; i++) {
    if (
      sanitizeColumnName(proposal.errors[i].column) === sanitizeColumnName(columnName) &&
      proposal.errors[i].line === index + 1
    ) {
      return true;
    }
  }
  return false;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getProposalsValue = (value: any, type: string): string => {
  if (isEmpty(value) || isNil(value)) return 'Formato inválido';
  if (type === 'birthDate') {
    const date = new Date(value as string);
    if (isNaN(date.getTime())) {
      return 'Formato inválido';
    }
    return formatToDate(date);
  }
  if (type === 'phone') {
    const phoneInfos = (value as string).split('-');
    return `+${phoneInfos[0]} ${formatToPhone(phoneInfos[1])}`;
  }
  if (type === 'cpf') return formatToCPF(value as string);
  if (type === 'addressPostalCode') return formatToCEP(value as string);

  return value ?? '-';
};

export const isSimplifiedEmployees = (data: unknown): data is SimplifiedEmployees[] => {
  return Array.isArray(data) && data.every((item) => 'status' in item && 'name' in item);
};
